<template>
	<!-- 注册 -->
	<div id="register-view" class="register-view">
		<form class="form-structor" method="post" action="">
			<div class="signup">
				<h2 class="form-title" id="signup"><span>或</span>注册</h2>
				<div class="form-holder">
					<input type="text" class="input" name="username" placeholder="用户名">
					<input type="email" class="input" name="email" placeholder="邮箱">
					<input type="password" class="input" name="password" placeholder="密码">
					<div class="school-box row">
						<div class="school">
							<el-select v-model="value" filterable placeholder="请选择学校">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="grade-class row">
						<div class="grade">
							<el-select v-model="value" filterable placeholder="请选择年级">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
						<div class="c_lass">
							<el-select v-model="value" filterable placeholder="请选择班级">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="sure">
						<input type="text" placeholder="验证码" class="code input" name="code" />
						<img onclick="changeCode(this)" class="code_icon" src="">
					</div>
				</div>
				<button class="submit-btn">注册</button>
			</div>
			<div class="login slide-up">
				<div class="center">
					<h2 class="form-title" id="login"><span>或</span>
						<router-link to="/login">登录</router-link>
					</h2>
					<div class="form-holder">
						<input type="email" class="input" placeholder="邮箱">
						<input type="password" class="input" placeholder="密码">
					</div>
					<button class="submit-btn">登录</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				options: [{
					value: '选项1',
					label: '黄金糕'
				}, {
					value: '选项2',
					label: '双皮奶'
				}, {
					value: '选项3',
					label: '蚵仔煎'
				}, {
					value: '选项4',
					label: '龙须面'
				}, {
					value: '选项5',
					label: '北京烤鸭'
				}],
				value: ''
			}
		},
		mounted() {

			let signupBtn = document.getElementById('signup');
			signupBtn.addEventListener('click', (e) => {
				let parent = e.target.parentNode;
				Array.from(e.target.parentNode.classList).find((element) => {
					if (element !== "slide-up") {
						parent.classList.add('slide-up')
					} else {
						loginBtn.parentNode.parentNode.classList.add('slide-up')
						parent.classList.remove('slide-up')
					}
				});
			});

		}
	}
	// if ($ {
	// 		msg != null
	// 	}) {
	// 	alert("${msg}")
	// }

	// function changeCode(e) {
	// 	let time = new Date().getMilliseconds();
	// 	e.src = "${pageContext.request.contextPath}/captche?" + time;
	// }
</script>


<!-- <style scoped="scoped" src="@/assets/css/register/style.css"></style> -->
<style scoped="scoped">
	.form-structor {
		background-color: #222;
		border-radius: 15px;
		height: 550px;
		width: 350px;
		position: relative;
		overflow: hidden;
	}

	.form-structor::after {
		content: "";
		opacity: 0.8;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-repeat: no-repeat;
		background-position: left bottom;
		background-size: 500px;
	}

	.form-structor .signup {
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		width: 65%;
		z-index: 5;
		-webkit-transition: all 0.3s ease;
	}

	.form-structor .signup.slide-up {
		top: 5%;
		-webkit-transform: translate(-50%, 0%);
		-webkit-transition: all 0.3s ease;
	}

	.form-structor .signup.slide-up .form-holder,
	.form-structor .signup.slide-up .submit-btn {
		opacity: 0;
		visibility: hidden;
	}

	.form-structor .signup.slide-up .form-title {
		font-size: 1em;
		cursor: pointer;
	}

	.form-structor .signup.slide-up .form-title span {
		margin-right: 5px;
		opacity: 1;
		visibility: visible;
		-webkit-transition: all 0.3s ease;
	}

	.form-structor .signup .form-title {
		color: #fff;
		font-size: 1.7em;
		text-align: center;
	}

	.form-structor .signup .form-title span {
		color: rgba(0, 0, 0, 0.4);
		opacity: 0;
		visibility: hidden;
		-webkit-transition: all 0.3s ease;
	}

	.form-structor .signup .form-holder {
		border-radius: 15px;
		background-color: #fff;
		overflow: hidden;
		margin-top: 50px;
		opacity: 1;
		visibility: visible;
		-webkit-transition: all 0.3s ease;
	}

	.form-structor .signup .form-holder .input {
		border: 0;
		outline: none;
		box-shadow: none;
		display: block;
		height: 30px;
		line-height: 30px;
		padding: 8px 15px;
		border-bottom: 1px solid #eee;
		width: 100%;
		font-size: 12px;
	}

	.form-structor .signup .form-holder .input:last-child {
		border-bottom: 0;
	}

	.form-structor .signup .form-holder .input::-webkit-input-placeholder {
		color: rgba(0, 0, 0, 0.4);
	}

	.form-structor .signup .submit-btn {
		background-color: rgba(0, 0, 0, 0.4);
		color: rgba(255, 255, 255, 0.7);
		border: 0;
		border-radius: 15px;
		display: block;
		margin: 15px auto;
		padding: 15px 45px;
		width: 100%;
		font-size: 13px;
		font-weight: bold;
		cursor: pointer;
		opacity: 1;
		visibility: visible;
		-webkit-transition: all 0.3s ease;
	}

	.form-structor .signup .submit-btn:hover {
		transition: all 0.3s ease;
		background-color: rgba(0, 0, 0, 0.8);
	}

	.form-structor .login {
		position: absolute;
		top: 20%;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #fff;
		z-index: 5;
		-webkit-transition: all 0.3s ease;
	}

	.form-structor .login::before {
		content: "";
		position: absolute;
		left: 50%;
		top: -20px;
		-webkit-transform: translate(-50%, 0);
		background-color: #fff;
		width: 200%;
		height: 250px;
		border-radius: 50%;
		z-index: 4;
		-webkit-transition: all 0.3s ease;
	}

	.form-structor .login .center {
		position: absolute;
		top: calc(50% - 10%);
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		width: 65%;
		z-index: 5;
		-webkit-transition: all 0.3s ease;
	}

	.form-structor .login .center .form-title {
		color: #000;
		font-size: 1.7em;
		text-align: center;
	}

	.form-structor .login .center .form-title span {
		color: rgba(0, 0, 0, 0.4);
		opacity: 0;
		visibility: hidden;
		-webkit-transition: all 0.3s ease;
	}

	.form-structor .login .center .form-holder {
		border-radius: 15px;
		background-color: #fff;
		border: 1px solid #eee;
		overflow: hidden;
		margin-top: 50px;
		opacity: 1;
		visibility: visible;
		-webkit-transition: all 0.3s ease;
	}

	.form-structor .login .center .form-holder .input {
		border: 0;
		outline: none;
		box-shadow: none;
		display: block;
		height: 30px;
		line-height: 30px;
		padding: 8px 15px;
		border-bottom: 1px solid #eee;
		width: 100%;
		font-size: 12px;
	}

	.form-structor .login .center .form-holder .input:last-child {
		border-bottom: 0;
	}

	.form-structor .login .center .form-holder .input::-webkit-input-placeholder {
		color: rgba(0, 0, 0, 0.4);
	}

	.form-structor .login .center .submit-btn {
		background-color: #6B92A4;
		color: rgba(255, 255, 255, 0.7);
		border: 0;
		border-radius: 15px;
		display: block;
		margin: 15px auto;
		padding: 15px 45px;
		width: 100%;
		font-size: 13px;
		font-weight: bold;
		cursor: pointer;
		opacity: 1;
		visibility: visible;
		-webkit-transition: all 0.3s ease;
	}

	.form-structor .login .center .submit-btn:hover {
		transition: all 0.3s ease;
		background-color: rgba(0, 0, 0, 0.8);
	}

	.form-structor .login.slide-up {
		top: 90%;
		-webkit-transition: all 0.3s ease;
	}

	.form-structor .login.slide-up .center {
		top: 10%;
		-webkit-transform: translate(-50%, 0%);
		-webkit-transition: all 0.3s ease;
	}

	.form-structor .login.slide-up .form-holder,
	.form-structor .login.slide-up .submit-btn {
		opacity: 0;
		visibility: hidden;
		-webkit-transition: all 0.3s ease;
	}

	.form-structor .login.slide-up .form-title {
		font-size: 1em;
		margin: 0;
		padding: 0;
		cursor: pointer;
		-webkit-transition: all 0.3s ease;
	}

	.form-structor .login.slide-up .form-title span {
		margin-right: 5px;
		opacity: 1;
		visibility: visible;
		-webkit-transition: all 0.3s ease;
	}

	#register-view {
		width: 100%;
		height: calc(100vh + 10px);
		background: url(https://up.imold.wang/sourceshop/assets/img/loginb.jpg) no-repeat center center/cover;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.form-structor {
		height: 500px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	}

	.form-structor::after {
		background-image: url(../../assets/img/32.jpg);
	}

	.code {
		margin-right: 20px;
	}

	.sure {
		display: flex;
		align-items: center;
	}

	.code_icon {
		width: 95px;
		height: 36px;
	}
</style>
